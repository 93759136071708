import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { ApiService } from '../ApiService';
import { JwtService } from './jwt.service';
import { Identity } from '../../interfaces';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  private currentIdentitySubject = new BehaviorSubject<Identity>({} as Identity);
  public currentIdentity = this.currentIdentitySubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private _baseUrl = '/Identities';

  constructor (
    private _apiService: ApiService,
    private _jwtService: JwtService,
    private _router: Router,
  ) {}

  public populate() {
    if (this._jwtService.getIdentity()) {
      this._apiService.get(`${this._baseUrl}/status`, undefined, "text")
        .subscribe({
          next: () => {
            this.setAuth(this._jwtService.getIdentity())
          },
          error: () => {
            this.purgeAuth();
          }
        });
    } else {
      this.purgeAuth();
    }
  }

  public setAuth(identity: Identity) {
    this._jwtService.saveIdentity(identity);
    this.currentIdentitySubject.next(identity);
    this.isAuthenticatedSubject.next(true);
  }

  public purgeAuth(withRedirect = false) {
    if (this._jwtService.getIdentity()) {
      this.revoksTokens();
    }
    this._jwtService.destroyIdentity();
    this.currentIdentitySubject.next({} as Identity);
    this.isAuthenticatedSubject.next(false);
    if (withRedirect) {
      this._router.navigateByUrl('/auth/login');
    }
  }

  public attemptAuth(credentials: any): Observable<Identity> {
    return this._apiService.post(this._baseUrl + '/login', credentials)
      .pipe(map(
        data => {
          this.setAuth(data);
          return data;
        }
      ));
  }

  public revoksTokens(): void {
    const body = {refreshToken: this._jwtService.getRefreshToken()};
    this._apiService.post(this._baseUrl + '/logout', body).subscribe();
  }

  public getCurrentIdentity(): Identity {
    return this.currentIdentitySubject.value;
  }
}
