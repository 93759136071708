import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

import { IdentityService } from '../services';

@Directive({ selector: '[appShowAuthed]' })
export class ShowAuthedDirective implements OnInit {
  @Input() set appShowAuthed(condition: boolean) {
    this._condition = condition;
  }

  constructor(
    private _templateRef: TemplateRef<any>,
    private _identityService: IdentityService,
    private _viewContainer: ViewContainerRef
  ) {}

  private _condition!: boolean;
  private _isCreated = false;

  ngOnInit() {
    this._identityService.isAuthenticated.subscribe(
      (isAuthenticated) => {
        if (isAuthenticated && this._condition && !this._isCreated ||
          !isAuthenticated && !this._condition && !this._isCreated) {
          this._isCreated = true;
          this._viewContainer.createEmbeddedView(this._templateRef);
        } else if (isAuthenticated && !this._condition || !isAuthenticated && this._condition) {
          this._viewContainer.clear();
          this._isCreated = false;
        }
      }
    );
  }
}
