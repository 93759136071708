import { Component, OnInit } from '@angular/core';
import { IdentityService, JwtService, LocalStorageService } from './core/services';
import { IClaim } from './core/interfaces/auth/IClaim';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isCollapsed = false;
  public activatedComponent: any;
  public currentUser: IClaim | null = null;
  public readonly menuItems = environment.menuItems;

  constructor (
    private readonly _identityService: IdentityService,
    private readonly _jwtService: JwtService,
    private _localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this._identityService.populate();
    this._identityService.isAuthenticated.subscribe(this.getUserDetails.bind(this));
  }

  getUserDetails(val: boolean) {
    this.currentUser = val ? this._jwtService.getIdentity().claims : null;
    if (this.currentUser) {
      this._localStorageService.setLocalStorageItemKey(this.currentUser.userId);
    }
  }

  public onLogout() {
    this._identityService.purgeAuth(true)
  }
}
