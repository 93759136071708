import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input() set fullName({ firstName, lastName }: { firstName: string; lastName: string; }) {
    this._firstName = firstName;
    this._lastName = lastName;
    this.rebuildAvatar();
  }
  @Input() set sizePx(val: number) {
    this._sizePx = val;
    this.calculate();
  }

  private readonly _defaultProportion = [160, 50];

  text = '';
  fontSize = this._defaultProportion[1];
  size = this._defaultProportion[0];
  private _firstName!: string;
  private _lastName!: string;
  private _sizePx!: number;

  constructor() { }

  get sizePx(): number {
    return this._sizePx;
  }

  private calculate() {
    this.fontSize = (this._defaultProportion[1] * this.sizePx) / this._defaultProportion[0];
    this.size = this.sizePx;
  }

  private rebuildAvatar() {
    this.text = `${ this._firstName.charAt(0).toUpperCase() }${ this._lastName.charAt(0).toUpperCase() }`;
  }
}
