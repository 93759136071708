export interface IExportTable {
  [key: string]: any;
  exportTable: string;
  operationId?: string;
  userId?: string;
  sortField?: string | null
  sortOrder?: string | null;
  selectedFields?: {
    field: string;
    label: string
  }[];
  fields?: {
    label: string;
    value: string;
  }[];
  filter?: { key: string; value: any[]; }[];
  search?: string[];
  targetType?: string;
}
