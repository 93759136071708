import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ModalGeneric } from '../../generics';
import { IExportTable } from '../../../core/interfaces/';
import { DocumentTypes } from '../../../core/enums';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ExportService, JwtService, NotificationService } from '../../../core/services';
import { ToolsService } from '../../../core/services';
import { ToolsErrorResolverService } from '../../../core/services/error-resolvers';

@Component({
  selector: 'app-export-table-modal',
  templateUrl: './export-table-modal.component.html',
  styleUrls: ['./export-table-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportTableModalComponent extends ModalGeneric implements OnInit {

  public override title = 'Export';
  public readonly documentTypes = DocumentTypes;
  public fields!: { label: string; value: string; }[];
  public form!: FormGroup;

  public readonly _formFields = {
    'selectedFields': [[]],
    'targetType': [this.documentTypes.Csv],
    'withTableParams': [false]
  };
  private _tableDetails!: IExportTable;

  constructor (
    cdr: ChangeDetectorRef,
    private readonly _formBuilder: FormBuilder,
    private readonly _jwtService: JwtService,
    private readonly _toolsService: ToolsService,
    private readonly _exportService: ExportService,
    private readonly _notificationService: NotificationService,
    private readonly _errorResolverService: ToolsErrorResolverService,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
  }

  private initForm(): void {
    this.form = this._formBuilder.group(this._formFields);
    this.form.get('selectedFields')!.patchValue(this.fields.map(({ value }) => value));
  }

  public override showModal(tableDetails: IExportTable): void {
    this._tableDetails = tableDetails;
    this.fields = tableDetails.fields!;
    this.initForm();
    super.showModal();
  }

  public exportTable(): void {
    const { selectedFields, withTableParams, targetType } = this.form.value;
    const { exportTable, sortField, sortOrder, filter, search } = this._tableDetails;
    let tableDetails: IExportTable = {
      exportTable,
      selectedFields: this.fields.filter(({ value }) => selectedFields.includes(value)).map((field) => {
        return {
          field: field.value,
          label: field.label
        } as { label: string; field: string; };
      }),
      operationId: Guid.create().toString(),
      userId: this._jwtService.getIdentity().claims.userId,
      targetType
    };

    if (withTableParams) {
      tableDetails = {
        ...tableDetails,
        sortField,
        sortOrder,
        search,
        filter
      };

      for (const key in tableDetails) {
        if (tableDetails.hasOwnProperty(key) && tableDetails[key] === null) {
          delete tableDetails[key];
        }
      }
    }
    this._toolsService.exportTable(tableDetails).subscribe(() => {
      this._exportService.operationId = tableDetails.operationId!;
      this._notificationService.pushSuccess('Success', 'Document is generated');
      super.closeModal();
    }, ({ errorCode }) => this._notificationService.pushError('Error', this._errorResolverService.getMessage(errorCode ?? '')));
  }
}
