import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/welcome' },
  { path: 'welcome',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },
  { path: 'team',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/team/team.module').then(m => m.TeamModule) },
  { path: 'equipment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/equipment/equipment.module').then(m => m.EquipmentModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
