<nz-layout class="app-layout">
  <nz-sider
    *appShowAuthed="true"
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <img src="./assets/img/logo/CC-color.png" alt="logo">
    </div>
    <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <ng-container *ngFor="let item of menuItems; let i = index">
        <li
          *ngIf="item.elements"
          nz-submenu
          [nzOpen]="item.isOpen && !isCollapsed"
          nzTitle="{{ item.name }}"
          nzIcon="{{ item.icon }}">
          <ul>
            <li nz-menu-item nzMatchRouter [nzMatchRouterExact]="subitem.exact" *ngFor="let subitem of item.elements">
              <a routerLink="{{item.routerLink}}{{ subitem.routerLink }}">{{ subitem.name }}</a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header *appShowAuthed="true">
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger"
               nz-icon
               [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></i>
        </span>
        <div class="app-header__options">
      <span class="avatar-header" nz-dropdown [nzDropdownMenu]="menu" [nzDisabled]="!currentUser" [nzTrigger]="'click'">
            <nz-avatar [nzSize]="40" nzIcon="user" *ngIf="!currentUser"></nz-avatar>
            <app-avatar
              *ngIf="currentUser"
              [sizePx]="40"
              [fullName]="{ firstName: currentUser!.firstName, lastName: currentUser!.lastName }"
            ></app-avatar>
          </span>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>
                <a (click)="onLogout()">Logout</a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>

    </nz-header>
    <nz-content>
      <router-outlet (activate)="activatedComponent = $event"></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>
