import { Injectable } from '@angular/core';
import { Identity } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  public getIdentity(): Identity {
    return (window.localStorage['identity'] !== undefined ? JSON.parse(window.localStorage['identity']) : false);
  }

  public getRefreshToken(): string {
    const identity = this.getIdentity();
    if (identity) {
      return identity.refreshToken;
    }
    return '';
  }

  public saveIdentity(identity: Identity) {
    window.localStorage['identity'] = JSON.stringify(identity);
  }

  public destroyIdentity() {
    window.localStorage.removeItem('identity');
  }
}
