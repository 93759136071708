import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, HostListener, OnDestroy,
  OnInit, Renderer2, ViewChild,
} from '@angular/core';
import { NotificationService } from '../../core/services';
import { GenericService } from '../../core/services/GenericService';
import { ITableResults } from '../../core/interfaces';
import { NzTableComponent, NzTableQueryParams } from 'ng-zorro-antd/table';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { KeepStateDirective } from '../../core/directives';
import { BaseServiceErrorResolverService } from '../../core/services/error-resolvers/base-service-error-resolver.service';
import { ExportTableModalComponent } from '../modals/export-table-modal/export-table-modal.component';
import { IExportTable } from '../../core/interfaces';
import { ExportTableTypes } from '../../core/enums';
import { finalize, map } from 'rxjs/operators';

@Component({
  template: ''
})
export abstract class ListComponentGeneric<T> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('tableRef') tableRef!: NzTableComponent<any>;
  @ViewChild('exportTableModalComponent') exportTableModalComponent!: ExportTableModalComponent;
  @ViewChild(KeepStateDirective, {static: true}) keepStateDirective!: KeepStateDirective;

  public data: ITableResults<T> = {
    results: [],
    info: {
      page: 1,
      results: 0,
      totalResults: 0,
    }
  };
  protected subscriptions: Subscription[] = [];
  public loading = false;
  public pageSize = environment.defaultResultsPerPage;
  public checked = false;
  public indeterminate = false;
  public setOfCheckedId = new Set<number>();
  protected searchColumns: string[]|null = null;
  public tableMaxWidth!: string;
  public currentSearch = '';
  public exportTableTypes = ExportTableTypes;
  protected predefinedFilters: { key: string; value: string[] }[] = [];

  protected lastLoadParams: {
    pageIndex: number | null,
    pageSize: number | null,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[]
    search: { key: string; value: string[] }[]
  } = {
    pageIndex: null,
    pageSize: null,
    sortField: null,
    sortOrder: null,
    filter: [],
    search: []
  };

  protected constructor(
    protected _listService: GenericService<T>,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: BaseServiceErrorResolverService
  ) { }

  @HostListener('window:resize')
  onResize() {
    this.setTableMaxWidth();
  }

  ngOnInit(): void {
    if (this.keepStateDirective && this.keepStateDirective.getValue() !== '') {
      const { pageIndex, pageSize, search } = this.keepStateDirective.getValue();
      this.data.info.page = pageIndex;
      this.pageSize = pageSize;
      this.currentSearch = search[0]?.value[0] ?? '';
    }
  }

  ngAfterViewInit(): void {
    this.setTableMaxWidth();
  }

  private setTableMaxWidth(): void {
    setTimeout(() => this.tableMaxWidth = this.renderer.selectRootElement('nz-table', true).offsetWidth + 'px');
  }

  onRefreshList(defValues = true): void {
    if (defValues) {
      this.loadDefault();
      return;
    }
    const { pageIndex, pageSize, filter, sortField, sortOrder, search } = this.lastLoadParams;
    this.load(pageIndex!, pageSize!, sortField, sortOrder, filter, search);
  }

  public exportBtnClicked(exportTableType: ExportTableTypes): void {
    const { sortField, sortOrder, filter } = this.lastLoadParams;
    const tableRef = this.tableRef as any;
    const tableParams: IExportTable = {
      fields:  [...tableRef.elementRef.nativeElement.querySelectorAll('th')].map((el: HTMLElement) => {
        return {
          label: el.textContent!,
          value: el.getAttribute('nzColumnKey')!
        }
      }).filter(({ label, value }) => label !== '' ),
      search: this.assignSearch(this.currentSearch).map(({ key, value }) => `${ key }:${ value }`),
      sortField,
      sortOrder,
      filter,
      exportTable: exportTableType
    };
    this.exportTableModalComponent.showModal(tableParams);
  }

  protected loadDefault() {
    this.currentSearch = '';
    this.load(1 , this.pageSize, null, null, [], []);
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    const filter = params.filter;

    const search = this.assignSearch(this.currentSearch);
    if (pageSize !== this.lastLoadParams.pageSize ||
      (pageIndex !== this.lastLoadParams.pageIndex && pageIndex > 0) ||
      sortField !== this.lastLoadParams.sortField ||
      sortOrder !== this.lastLoadParams.sortOrder ||
      (filter.length > 0  && this.lastLoadParams.filter.length > 0 && filter !== this.lastLoadParams.filter)
    ) {
      this.load(pageIndex, pageSize, sortField, sortOrder, filter, search);
    }
  }

  load(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
  ): void {
    this.lastLoadParams = {
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      search
    };
    filter = [...filter, ...this.predefinedFilters];
    if (this.keepStateDirective) {
      this.keepStateDirective.item = {
        ...this.lastLoadParams
      };
    }
    this.callService(pageIndex, pageSize, sortField, sortOrder, filter, search);
  }

  callService( pageIndex: number,
               pageSize: number,
               sortField: string | null,
               sortOrder: string | null,
               filters: Array<{ key: string; value: string[] }>,
               search: Array<{ key: string; value: string[] }>) {
    this.loading = true;
    this._listService.getQueryableAll(pageIndex, pageSize, sortField, sortOrder, filters, search).subscribe(res => {
      this.loading = false;
      this.data = res;
      this._cdr.detectChanges();
    }, this.parseError);
  }

  parseError = (data: { InternalCode: string}) => {
    this._notifyService.pushError(undefined, this._errorResolver.getMessage((data && data.InternalCode) ? data.InternalCode : ''));
  }

  deleteElement = (id: number) => {
    this.loading = true;
    this._listService.delete(id.toString()).pipe(finalize(() => this.loading = false)).subscribe(() => {
      this.onRefreshList(false);
    }, this.parseError);
  }

  search = (value: string) => {
    this.currentSearch = value;
    if (value.trim() !== '') {
      this._listService.getSimple(value)
      .pipe(map(data => data.map((item: { id: string; value: string; }) => {
        return { name: item.value }
      })))
      .subscribe((res) => {
        this.loading = false;
        this.data.results = res;
        this._cdr.detectChanges();
      }, this.parseError);
    }
  }

  private buildSearchColumns() {
    this.searchColumns = (this.tableRef.nzData.length > 0 ? Object.keys(this.tableRef.nzData[0]) : []);
  }

  private assignSearch(value: string) {
    if (this.searchColumns === null || this.searchColumns === undefined) {
        this.buildSearchColumns();
      }
    if (value.trim() === '') {
      return [];
    }
    return this.searchColumns!.map((el) => ({ key: el, value: [value] }));
  }

  protected updateCheckedSet(id: number, checked: boolean): void {
    checked ? this.setOfCheckedId.add(id) : this.setOfCheckedId.delete(id);
  }

  protected refreshCheckedStatus(): void {
    this.checked = this.data.results.every((item: any) => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.data.results.some((item: any) => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  public onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  public onAllChecked(value: boolean): void {
    this.data.results.forEach((item: any) => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
