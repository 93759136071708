import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiService, NotificationService } from '../../core/services';
import { UploadService } from '../../core/services/UploadService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-uploader',
  templateUrl: 'uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {
  @ViewChild('uploadInput') uploadInput!: ElementRef;

  @Input() fileType!: string;
  @Output() uploadedKey = new EventEmitter<string>();

  public isUploading = false;

  private _file!: File;
  private _key!: string;

  constructor(
    private readonly _notifyService: NotificationService,
    private readonly _uploadService: UploadService,
    private readonly _apiService: ApiService,
    private readonly _http: HttpClient
  ) {}

  public onUpload(e: Event) {
    if (!(<HTMLInputElement>e.target).files) {
      return;
    }
    this._file = (<HTMLInputElement>e.target).files![0];

    (this.fileType === 'Avatar') ? this.validateAvatar() : this.validateInvoice();
  }

  private validateAvatar() {
    if (['image/jpeg', 'image/png'].indexOf(this._file.type) === -1) {
      this.handleError('Type','Avatar must be of JPEG or PNG type!');
      return;
    }

    if (this._file.size > 3000000) {
      this.handleError('File size','File size cannot exceed 3MB!');
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(this._file);

    fileReader.onloadend = () => {
      const image = new Image();
      image.src = (fileReader.result as string);

      image.onload = () => {
        const height = image.height;
        const width = image.width;

        if (width > 320 || height > 320) {
          this.handleError('Image resolution', 'Resolution cannot exceed 320 x 320!')
          return;
        }
        this.startUploading()
      }
    }
  }

  private validateInvoice(): void {
    if (this._file.type !== 'application/pdf') {
      this.handleError('Type','Unacceptable file type!');
      return;
    }

    this.startUploading();
  }

  private startUploading(): void {
    this.isUploading = true;

    this._uploadService.getUploadUrl(this._file.name, this.fileType)
    .pipe(switchMap(data => {
      this._key = data.key;

      return this._http.put(data.url, this._file)
    })).subscribe({
      next: () => {
        this.uploadedKey.emit(this._key);
        this.isUploading = false;
      },
      error: () => {
        this.isUploading = false;
        this.handleError('Failed', 'Error occurred while uploading file!');
      }
    })
  }

  private handleError(errorType: string, errorMsg: string): void {
    this._notifyService.pushError(errorType, errorMsg);
    this.uploadInput.nativeElement.value = '';
  }
}
