import { NgModule } from '@angular/core';
import { ShowAuthedDirective } from './directives/show-authed.directive';
import { KeepStateDirective } from './directives/keep-state.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';

@NgModule({
  declarations: [
    ShowAuthedDirective,
    KeepStateDirective,
    AutoFocusDirective
  ],
  imports: [],
  exports: [
    ShowAuthedDirective,
    KeepStateDirective,
    AutoFocusDirective
  ],
  providers: []
})
export class CoreModule {}
