import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-subpage-header',
  templateUrl: './subpage-header.component.html',
  styleUrls: ['./subpage-header.component.scss']
})
export class SubpageHeaderComponent {
  @Input() title!: string;
  @Input() tooltipText!: string;
}
