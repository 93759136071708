export const menuItems = [
  {
    name: 'Dashboard',
    icon: 'dashboard',
    routerLink: '',
    isOpen: true,
    elements: [
      {
        name: 'Welcome',
        routerLink: '/welcome',
        isMobile: false,
        exact: true
      }
    ]
  },
  {
    name: 'Team',
    icon: 'team',
    routerLink: '/team',
    elements: [
      {
        name: 'Employees',
        routerLink: '/employees',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Department',
        routerLink: '/department',
        isMobile: false,
        exact: true,
      },
      {
        name: 'Positions',
        routerLink: '/positions',
        isMobile: false,
        exact: true,
      }
    ]
  },
  {
    name: 'Equipment',
    icon: 'laptop',
    routerLink: '/equipment',
    elements: [
      {
        name: "Equipment's Type",
        routerLink: '/equipment-type',
        isMobile:false,
        exact: true
      },
      {
        name: "Equipment's Manufacturer",
        routerLink: '/equipment-manufacturer',
        isMobile:false,
        exact: true
      },
      {
        name: 'Overview',
        routerLink: '/overview',
        isMobile: false,
        exact: true
      },
      {
        name: 'Invoices',
        routerLink: '/invoices',
        isMobile: false,
        exact: true
      }
    ]
  }
];
