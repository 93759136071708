import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor (
    private _http: HttpClient,
  ) {}

  private formatErrors(error: any) {
    console.log(error);
    return throwError(error.error);
  }

  public get(path: string, params: HttpParams = new HttpParams(), responseType: string = "json" ): Observable<any> {
    return this._http.get(`${environment.apiUrl}${path}`, { params, responseType: responseType as "json" },)
      .pipe(catchError(this.formatErrors));
  }

  public getFile(path: string, contentType: string, params: HttpParams = new HttpParams()): Observable<Blob> {
    return this._http.get(path,
      {
        params,
        headers: {
          'Accept': contentType
        },
        responseType : 'blob',
      },
      ).pipe(
        catchError(this.formatErrors),
    );
  }

  public put(path: string, body = {}): Observable<any> {
    return this._http.put(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).pipe(catchError(this.formatErrors));
  }

  public post(path: string, body = {}, params: HttpParams = new HttpParams(), responseType?: string): Observable<any> {
    return this._http.post(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body),
      {
        params: params,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: responseType ? responseType as 'json' : 'json'
      }
    ).pipe(catchError(this.formatErrors));
  }

  public postFormData(path: string, body = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this._http.post(
      `${environment.apiUrl}${path}`,
      body,
      {
        params: params,
      }
    ).pipe(catchError(this.formatErrors));
  }

  public delete(path: string, params?: HttpParams): Observable<any> {
    return this._http.delete(
      `${environment.apiUrl}${path}`, { params }
    ).pipe(catchError(this.formatErrors));
  }
}
