import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class DictionaryInterceptor implements HttpInterceptor {

  constructor(private _router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('Dictionaries') || req.params.get('Dictionary')) {
      return next.handle(req);
    }
    const currentUrl = this._router.routerState.snapshot.url;

    const header = req.method === 'GET' ? 'Dictionary' : 'Type';
    const headerValue = currentUrl.endsWith('equipment-type') ? 'EquipmentType' : 'EquipmentManufacturer';
    let newRequest = req.clone({ params: req.params.append(header, headerValue) });
    if (req.method === 'POST') {
      newRequest = newRequest.clone({ params: newRequest.params.append('Name', JSON.parse(req.body).Name)});
    }

    return next.handle(newRequest);
  }
}
