import { Observable } from 'rxjs';
import { ApiService } from './ApiService';
import { HttpParams } from '@angular/common/http';
import { IService } from '../interfaces';
import { ITableResults } from '../interfaces';

export class GenericService<T> implements IService<T> {

  protected baseUrl = '';

  constructor(public apiService: ApiService) { }

  public getSimple(search: string): Observable<any> {
    return this.apiService.get(`${this.baseUrl}/simple`, new HttpParams().set('Search', search) );
  }

  public getQueryableAll(pageIndex: number,
                   pageSize: number,
                   sortField: string | null,
                   sortOrder: string | null,
                   filters: Array<{ key: string; value: string[] }>,
                   search: Array<{ key: string; value: string[] }>,
                   dictionary?: string): Observable<ITableResults<T>> {
    let params = this.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search);

    if (dictionary) {
      params = params.set('Dictionary', dictionary);
    }

    return this.apiService.get(`${this.baseUrl}`, params );
  }

  public getById(id: string): Observable<T> {
    return this.apiService.get(`${this.baseUrl}/${id}`);
  }

  public delete(id: string): Observable<T> {
    return this.apiService.delete(`${this.baseUrl}/${id}`);
  }

  public deleteAsQueryParam(id: number, param: string): Observable<T> {
    return this.apiService.delete(`${this.baseUrl}`,  new HttpParams().set(param, id));
  }

  public update(object: T, id: number) {
    return this.apiService.put(`${this.baseUrl}/${id}`, object);
  }

  public create(object: T) {
    return this.apiService.post(`${this.baseUrl}`, object, undefined, 'text');
  }

  protected prepareQueryableParams( pageIndex: number,
                                    pageSize: number,
                                    sortField: string | null,
                                    sortOrder: string | null,
                                    filters: Array<{ key: string; value: string[] }>,
                                    search: Array<{ key: string; value: string[] }>): HttpParams {
    let params = new HttpParams()
      .append('PageIndex', `${pageIndex}`)
      .append('PageSize', `${pageSize}`);
    if (sortField !== null) {
      params = params.append('SortField', `${sortField}`);
    }
    if (sortOrder !== null) {
      params = params.append('SortOrder', `${sortOrder}`);
    }
    if (filters.length > 0) {
      filters.forEach(filter => {
        if (filter.value) {
          filter.value.forEach(value => {
            if (value) {
              params = params.append('Filter', `${filter.key}:${value}`);
            }
          });
        }
      });
    }
    if (search?.length > 0) {
      search.forEach(el => {
        if (el.value) {
          el.value.forEach(value => {
            if (value) {
              params = params.append('Search', `${el.key}:${value}`);
            }
          });
        }
      });
    }

    return params;
  }
}
