import { Observable } from 'rxjs';
import { ITableResults } from '../interfaces';

export interface IService<T> {

  getQueryableAll( pageIndex: number,
                   pageSize: number,
                   sortField: string | null,
                   sortOrder: string | null,
                   filters: Array<{ key: string; value: string[] }>,
                   search: Array<{ key: string; value: string[] }>): Observable<ITableResults<T>>;

  getById(id: string): Observable<T>;
}
