import { Injectable } from '@angular/core';
import { ApiService } from './ApiService';
import { JwtService } from './auth';
import { IExportTable } from '../interfaces';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  private readonly _baseUrl = '/tools';

  constructor(
    private readonly _apiService: ApiService,
    private readonly _jwtService: JwtService
  ) {}

  // getDownloadUrl(documentKey: string) {
  //   return `${this._apiService.getApiUrl()}${this._baseUrl}/document?key=${documentKey}&token=${this._jwtService.getAccessToken()}`;
  // }

  getAmazonDownloadUrl(documentKey: string) {
    const param = new HttpParams().append('keys', documentKey);
    return this._apiService.get(`${ this._baseUrl }/download-url`, param);
  }

  // public getFile(documentKey: string, contentType: string): Observable<Blob> {
  //   return this._apiService.getFile(this.getDownloadUrl(documentKey), contentType);
  // }

  // getMassDownloadUrl(documentKeys: string[]) {
  //   return `${this._apiService.getApiUrl()}${this._baseUrl}/documents?keys=${documentKeys.join('&keys=')}&token=${this._jwtService.getAccessToken()}`;
  // }

  public exportTable(exportDetails: IExportTable): Observable<any> {
    return this._apiService.post(`${ this._baseUrl }/export-table`, exportDetails);
  }

  public clearCache(): Observable<any> {
    return this._apiService.post(`${ this._baseUrl}/clear-cache`)
  }
}
