import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('amazon') || req.url.includes('login')) {
      return next.handle(req);
    }

    const authData = localStorage.getItem('identity');

    if (authData) {
      const token = JSON.parse(authData).accessToken;
      const newReq = req.clone({ headers: req.headers.append(
         'Authorization', `Bearer ${token}` )
      });

      return next.handle(newReq);
    } else {
      return next.handle(req);
    }
  }
}
