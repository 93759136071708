import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent {
  @Input() loading = false;
  @Input() withExportOption = false;

  @Output() reload = new EventEmitter<void>();
  @Output() exportBtnClicked = new EventEmitter<void>();
}
