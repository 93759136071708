import { Injectable } from '@angular/core';
import { ApiService } from './ApiService';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private readonly _baseURL = '/Tools';

  constructor(private readonly _apiService: ApiService) {}

  public getUploadUrl(filename: string, filetype: string): Observable<{ url: string, key: string }> {
      return this._apiService.get(`${this._baseURL}/upload-url?FileName=${filename}&FileType=${filetype}`);
  }

  public getDownloadUrl(key: string): Observable<{ url: string, key: string }> {
    return this._apiService.get(`${this._baseURL}/download-url`,
      new HttpParams().set('keys', key)
    );
  }
}
