<form (ngSubmit)="submit()" class="search-container">
  <nz-input-group *ngIf="searchAfterSubmit; else searchOnDelay" nzSearch [nzAddOnAfter]="suffixIconButton">
    <input type="text" [(ngModel)]="searchValue" name="search-value" nz-input placeholder="input search text">
  </nz-input-group>

  <ng-template #suffixIconButton>
    <button type="submit" nz-button nzType="primary" nzSearch><i nz-icon nzType="search"></i></button>
  </ng-template>
</form>

<ng-template #searchOnDelay>
  <nz-input-group nzSearch>
    <input type="text" [(ngModel)]="searchValue" (ngModelChange)="searchValueChanged()"
           (blur)="removeSubscription()" name="search-value" nz-input placeholder="input search text">
  </nz-input-group>
</ng-template>
