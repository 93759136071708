import { NgModule } from '@angular/core';
import { InnerBoxComponent } from './inner-box/inner-box.component';
import { CommonModule } from '@angular/common';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AvatarComponent } from './avatar/avatar.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { IconsProviderModule } from '../icons-provider.module';
import { SubpageHeaderComponent } from './headers/subpage-header/subpage-header.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { HintIconComponent } from './hint-icon/hint-icon.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TableHeaderComponent } from './headers/table-header/table-header.component';
import { ExportTableModalComponent } from './modals/export-table-modal/export-table-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableSearchBarComponent } from './table-search-bar/table-search-bar.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { UploaderComponent } from './uploader/uploader.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

@NgModule({
  declarations: [
    InnerBoxComponent,
    AvatarComponent,
    SubpageHeaderComponent,
    HintIconComponent,
    TableHeaderComponent,
    ExportTableModalComponent,
    TableSearchBarComponent,
    UploaderComponent
  ],
  imports: [
    IconsProviderModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzNotificationModule,
    NzDropDownModule,
    NzAvatarModule,
    NzButtonModule,
    NzSpinModule,
    NzUploadModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzDatePickerModule,
    NzModalModule,
    NzDividerModule,
    NzToolTipModule,
    NzTableModule,
    NzTabsModule,
    NzCheckboxModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzTagModule,
    NzListModule,
    NzTypographyModule
  ],
  exports: [
    IconsProviderModule,
    InnerBoxComponent,
    AvatarComponent,
    HintIconComponent,
    TableHeaderComponent,
    SubpageHeaderComponent,
    ExportTableModalComponent,
    TableSearchBarComponent,
    NzNotificationModule,
    NzDropDownModule,
    NzAvatarModule,
    NzButtonModule,
    NzSpinModule,
    NzUploadModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzDatePickerModule,
    NzModalModule,
    NzDividerModule,
    NzToolTipModule,
    NzTableModule,
    NzTabsModule,
    NzCheckboxModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzTagModule,
    UploaderComponent,
    NzListModule,
    NzTypographyModule
  ]
})
export class UiModule {}
