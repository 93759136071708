export interface IUser {
  id: number;
  fullName: string;
  login:	string;
  email:	string;
  firstName:	string;
  lastName:	string;
  role:	string;
  isActive:	boolean;
  position: string;
  isOnline?: boolean;
  twoFactorAuthEnabled?: boolean;
  documentKey?: string;
}
