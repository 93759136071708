import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  MenuFoldOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  ThunderboltTwoTone,
  TeamOutline,
  SearchOutline,
  CloudOutline,
  BulbOutline,
  CompassOutline,
  RocketOutline,
  LinkOutline,
  ZoomInOutline,
} from '@ant-design/icons-angular/icons';

const icons = [
  MenuFoldOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  TeamOutline,
  ThunderboltTwoTone,
  SearchOutline,
  CloudOutline,
  BulbOutline,
  CompassOutline,
  RocketOutline,
  LinkOutline,
  ZoomInOutline
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [
    { provide: NZ_ICONS, useValue: icons }
  ]
})
export class IconsProviderModule {
}
