export class BaseServiceErrorResolverService {

  defaultMessage = 'Problem with the operation, please try again';

  codes: {[key: string]: string } = {

  };

  public getMessage(reason: string) {
    if (reason === null || reason === undefined) {
      return this.defaultMessage;
    }

    if (this.codes.hasOwnProperty(reason)) {
      return this.codes[reason];
    } else {
      return this.defaultMessage;
    }
  }
}

