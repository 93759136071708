import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hint-icon',
  templateUrl: './hint-icon.component.html',
  styleUrls: ['./hint-icon.component.scss']
})
export class HintIconComponent {
  @Input() tooltipText!: string;
}
