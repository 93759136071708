import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements OnInit {
  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    setTimeout(()=> {
      this._elementRef.nativeElement.focus();
    } , 0);
  }
}
