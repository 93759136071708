import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  template: ''
})
export abstract class ModalGeneric implements OnDestroy {
  public isVisible = false;
  public title = '';
  protected subscriptions: Subscription[] = [];

  protected constructor (
    private readonly cdr: ChangeDetectorRef
  ) {}


  public showModal(object: any | null = null): void {
    this.isVisible = true;
    this.cdr.markForCheck();
  }

  public closeModal(): void {
    this.isVisible = false;
    this.cdr.markForCheck();
  }

  public handleCancel(): void {
    this.closeModal();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
