import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { map, take, tap } from 'rxjs/operators';
import { IdentityService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _identityService: IdentityService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._identityService.isAuthenticated.pipe(
          take(1),
          map(isAuth => !isAuth),
          tap((state) => {
             if (!state) {
               this._router.navigate(['/']);
             }
          })
        );
    }
}
